.ccbw-event {
    display: flex;
    flex-direction: column;
}

.ccbw-event__image-container {
    align-items: center;
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: var(--margin-stack);
    order: 1;
    width: 100%;
}

.ccbw-event__image {
    flex-basis: 45%;
    margin: var(--margin-inline);
    margin-top: var(--space);
    width: 45%;
}

.ccbw-event__container {
    flex-basis: 100%;
    width: 100%;
}

.ccbw-event__header {
    display: flex;
}

.ccbw-event__time,
.ccbw-event__location {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-bold);
}

.ccbw-event__time {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    padding-right: var(--space-l);
    text-transform: uppercase;
}

.ccbw-event__time:before,
.ccbw-event__location:before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.ccbw-event__time:before {
    background-image: url(../../assets/icons/clock.svg);
}

.ccbw-event__location:before {
    background-image: url(../../assets/icons/map-pin.svg);
}

.ccbw-event__end-time,
.ccbw-event__address {
    color: var(--primary-color);
}

.ccbw-event__location {
    flex-grow: 1;
    margin-left: var(--space-l);
}

.ccbw-event__time {
    margin: 0;
}

.ccbw-event__start-time,
.ccbw-event__host {
    margin: var(--margin-stack-s);
}

.ccbw-event__address {
    font-size: var(--font-size);
    font-style: normal;
    font-weight: var(--font-weight);
}

.ccbw-event__title {
    font-weight: var(--font-weight-light);
    margin: var(--margin-stack);
}

.ccbw-event__details {
    margin: var(--margin-stack-s);
}

.ccbw-event__description {
    margin: var(--margin-stack-s);
}

.ccbw-event__link {
    display: inline-block;
    margin: var(--margin-stack);
}

.ccbw-event__breweries {
    font-size: var(--font-size-l);
    font-style: italic;
}


@media screen and (min-width: 768px) {
    .ccbw-event {
        flex-direction: row;
    }

    .ccbw-event__image-container {
        display: block;
        flex-basis: 20%;
        margin: var(--margin-inline-l);
        order: 0;
        width: 20%;
    }

    .ccbw-event__image {
        flex-basis: 100%;
        margin: var(--margin-stack);
        width: 100%;
    }

    .ccbw-event__image:nth-of-type(1) {
        margin: 0;
    }

    .ccbw-event__container {
        flex-basis: 80%;
        width: 80%;
    }
}

/* styling for the special festival event card*/
.ccbw-festival .ccbw-event__container {
    flex-basis: 100%;
    width: 100%;
}

.ccbw-festival .ccbw-event__header {
    margin: var(--margin-stack);
    flex-wrap: wrap;
}

.ccbw-event__times {
    display: flex;
    flex-basis: 100%;
    width: 100%;
}

.ccbw-festival .ccbw-event__time {
    text-transform: none;
}

.ccbw-event__time:nth-of-type(2) {
    border: 0;
    margin-left: var(--space-l)
}

.ccbw-festival .ccbw-event__end-time {
    margin: 0;
    text-transform: uppercase;
}

.ccbw-festival .ccbw-event__location {
    margin: var(--margin-stack);
    flex-basis: 100%;
    width: 100%;
}

.ccbw-event__button {
    appearance: none;
    border: 0;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    color: var(--color-white);
    font-size: var(--font-size-l);
    background-color: var(--color-green-40);
    padding: var(--padding-inset);
    width: 100%;
    margin: var(--margin-stack);
    text-decoration: none;
    display: block;
    text-align: center;
}

.ccbw-event__button:visited {
    background-color: var(--color-green-40);
    color: var(--color-white);
}

.ccbw-event__button:hover {
    cursor: pointer;
    background-color: var(--color-green-50);
    color: var(--color-white);
    text-decoration: none;
}

.ccbw-event__button:focus {
    background-color: var(--color-green-50);
    color: var(--color-white);
    text-decoration: none;
}

.ccbw-event__button:active {
    background-color: var(--color-green-60);
    color: var(--color-white);
}
