.contain {
    margin: 0 auto;
    max-width: var(--content-max-width);
}

.contain-extreme {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--content-max-width-half);
}

.sr-only {
    border: 0; 
    clip: rect(0,0,0,0);
    color: #000000;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    visibility: hidden;
}
