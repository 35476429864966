.ccbw-nav {
    --nav-link-color: var(--color-white);
    --nav-link-color-interaction: var(--color-white);
    --nav-link-color-active: var(--color-white);
    --nav-link-color-visited: var(--color-white);
    --nav-link-background-color: var(--color-white);
    --nav-link-background-color-interaction: var(--color-red-40);
    --nav-link-background-color-active: var(--color-red-60);

    flex-basis: 100%;
    padding: 0 var(--space-2xs);
    width: 100%;
}

.ccbw-nav__list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
}

.ccbw-nav__item {
    flex-shrink: 0;
    height: 42px;
    width: 42px;
}

.ccbw-nav__link {
    border-radius: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--nav-link-color);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
}

.ccbw-nav__link:visited {
    color: var(--nav-link-color-visited);
}

.ccbw-nav__link:active {
    color: var(--nav-link-color-active);
}

.ccbw-nav__link:hover,
.ccbw-nav__link:focus {
    background-color: var(--nav-link-background-color-interaction);
    color: var(--nav-link-color-interaction);
}

.ccbw-nav__link--active,
.ccbw-nav__link:hover.ccbw-nav__link--active, 
.ccbw-nav__link:focus.ccbw-nav__link--active {
    background-color: var(--nav-link-background-color-active);
    border: 1px solid var(--nav-link-background-color-active);
}

.ccbw-nav__date {
    display: block;
}

.ccbw-nav__day {
    margin: 0 auto;
    display: none;
}

.ccbw-nav__day:first-letter {
    visibility: visible;
}

.ccbw-nav__date {
    font-size: var(--font-size);
}

@media screen and (min-width: 576px) {
    .ccbw-nav__item {
        height: 65px;
        width: 65px;
    }

    .ccbw-nav__day {
        font-size: var(--font-size);
        display: block;
    }
}
 
@media screen and (min-width: 768px) {
    .ccbw-nav {
        padding: 0;
    }

    .ccbw-nav__item {
        height: 80px;
        width: 80px;
    }

    .ccbw-nav__day {
        font-size: var(--font-size-s);
    }

    .ccbw-nav__date {
        font-size: var(--font-size-xl);
    }
}