.ccbw-header__logo-container {
    background-color: var(--primary-color-dark);
    padding: var(--space-xs) 0;
}

.ccbw-header__nav-container {
    background-color: var(--primary-color);
    padding: var(--space) 0;
}

.ccbw-header__container {
    display: flex;
    justify-content: center;
}

