h1,
h2,
h3,
h4, 
h5, 
h6 {
    font-family: var(--font-family-primary);
    font-weight: var(--font-weight);
    letter-spacing: var(--font-letter-spacing);
}

h1 {
    font-size: var(--font-size-5xl);
    margin: var(--margin-stack-xl);
}

h2 {
    font-size: var(--font-size-4xl);
    margin: var(--margin-stack-l);
}

h3 {
    font-size: var(--font-size-3xl);
    margin: var(--margin-stack);
}

h4 {
    font-size: var(--font-size-2xl);
    margin: var(--margin-stack-xl);
}

h5 {
    font-size: var(--font-size-xl);
    margin: var(--margin-stack-l);
}

h6 {
    font-size: var(--font-size-l);
    margin: var(--margin-stack);
}

p {
    line-height: 1.5;
    margin: var(--margin-stack-s);
}


h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: var(--margin-stack-xl);
}

h1 + p, 
h2 + p, 
h3 + p, 
h4 + p, 
h5 + p, 
h6 + p {
    margin: var(--margin-stack-s);
}

h1 + ol, 
h2 + ol, 
h3 + ol, 
h4 + ol, 
h5 + ol, 
h6 + ol {
    margin: var(--margin-stack-xs);
}

h1 + ul, 
h2 + ul, 
h3 + ul, 
h4 + ul, 
h5 + ul, 
h6 + ul {
    margin: var(--margin-stack-xs);
}

h1 + dl,
h2 + dl,
h3 + dl,
h4 + dl,
h5 + dl,
h6 + dl {
    margin: var(--margin-stack-xs);
}

h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6 {
    margin-top: 0;
}

a,
a:visited {
    --link-color: var(--color-blue-60);
    --link-color-interactive: var(--color-blue-40);
    --link-color: var(--color-green-60);
    --link-color-interactive: var(--color-green-40);
    
    color: var(--link-color);
}

a:hover,
a:focus,
a:active {
    color: var(--link-color-interactive);
}

ol,
ul {
    margin-top: var(--space);

}

ol ol,
ol ul,
ul ol,
ul ul  {
    margin-top: 0;
}