.ccbw-home-page {
    display: flex;
    flex-wrap: wrap;
}

.ccbw-home-page__banner {
    align-items: center;
    /* background-image: url('../../assets/images/video-still-min.png'); */
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    border-bottom: var(--border-height-extreme) solid var(--primary-color-dark);
    display: flex;
    flex-basis: 100%;
    height: 300px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: -1;
}

.ccbw-home-page__video {
    display: block;
    width: 100%;
    z-index: -100;
}


.ccbw-home-page__image {
    height: 200px;
    position: absolute;
    top: -8px;
}

.ccbw-home-page__events {
    background-color: var(--color-white);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: -116px var(--space) 0 var(--space);
    padding: var(--space-l) var(--space);
}

.ccbw-home-page__item {
    flex-basis: 50%;
    margin-top: var(--space-xl);
    width: 100%;
}

.ccbw-home-page__item {
    flex-basis: calc((100% / 2) - var(--space-xs) * 2);
    width: calc((100% / 2) - var(--space-xs) * 2);
}

.ccbw-home-page__item:nth-of-type(odd) {
    margin-right: var(--space-xs);
}

.ccbw-home-page__item:nth-of-type(1),
.ccbw-home-page__item:nth-of-type(2) {
    margin-top: 0;
}

.ccbw-home-page__day,
.ccbw-home-page__date {
    display: block;
    text-transform: uppercase;
}

.ccbw-home-page__day {
    font-size: var(--font-size-l);
}

.ccbw-home-page__date {
    font-size: var(--font-size-2xl);
    color: var(--primary-color-dark);
}

.ccbw-home-page__divider {
    background-color: var(--primary-color);
    display: block;
    height: 3px;
    margin: var(--margin-stack-xs);
    width: 80px;
}


@media screen and (min-width: 768px) {
    .ccbw-home-page__banner {
        height: 450px;
    }

    .ccbw-home-page__image {
        height: 400px;
        top: -40px;
    }
    
    .ccbw-home-page__events {
        padding: var(--space-xl) var(--space-xl) var(--space-2xl);
    }

    .ccbw-home-page__item {
        flex-basis: calc((100% / 3) - var(--space-xs) * 2);
        margin-top: var(--space-xl);
        width: calc((100% / 3) - var(--space-xs) * 2);
    }

    .ccbw-home-page__item {
        margin-right: var(--space-xs);
    }

    .ccbw-home-page__item:nth-of-type(3) {
        margin-top: 0;
    }

    .ccbw-home-page__item:last-of-type {
        margin-top: var(--space-xl);
    }
}
