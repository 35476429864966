:root {
    --color-black: #000000;
    --color-white: #ffffff;

    --color-red-40: #954341;
    --color-red-50: #832E2D;
    --color-red-60: #631616;
    --color-gold-40: #A27D3A;
    --color-gold-50: #9B742C;
    --color-gold-60: #745721;
    --color-cream-40: #F5F5F2;
    --color-cream-50: #D7D2C8;
    --color-cream-60: #C2BEB5;
    --color-brown-40: #695543;
    --color-brown-50: #533F2F;
    --color-brown-60: #312117;
    --color-blue-40: #383D86;
    --color-blue-50: #292F7D;
    --color-blue-60: #1E235E;
    --color-green-40: #16756F;
    --color-green-50: #056B65;
    --color-green-60: #03504C;

    /* Golden Ratio (1.618) with 16px Base */
    --space-2xl: 4.236rem; /* 67.77px */
    --space-xl: 2.618rem; /* 41.89px */
    --space-l: 1.618rem; /* 25.89px */
    --space: 1rem; /* 16px */
    --space-s: 0.618rem; /* 9.89px */
    --space-xs: 0.382rem; /* 6.11px */
    --space-2xs: 0.236rem; /* 3.78px */

    --margin-stack-2xl: var(--space-2xl) 0 0 0;
    --margin-stack-xl: var(--space-xl) 0 0 0;
    --margin-stack-l: var(--space-l) 0 0 0;
    --margin-stack: var(--space) 0 0 0;
    --margin-stack-s: var(--space-s) 0 0 0;
    --margin-stack-xs: var(--space-xs) 0 0 0;
    
    --margin-inline-2xl: 0 var(--space-2xl) 0 0;
    --margin-inline-xl: 0 var(--space-xl) 0 0;
    --margin-inline-l: 0 var(--space-l) 0 0;
    --margin-inline: 0 var(--space) 0 0;
    --margin-inline-s: 0 var(--space-s) 0 0;
    --margin-inline-xs: 0 var(--space-xs) 0 0;

    --padding-inset-2xl: var(--space-2xl);
    --padding-inset-xl: var(--space-xl);
    --padding-inset-l: var(--space-l);
    --padding-inset: var(--space);
    --padding-inset-s: var(--space-s);
    --padding-inset-xs: var(--space-xs);

    --padding-inset-squish-2xl: var(--space-2xl) calc(var(--space-2xl) / 2);
    --padding-inset-squish-xl: var(--space-xl) calc(var(--space-xl) / 2);
    --padding-inset-squish-l: var(--space-l) calc(var(--space-l) / 2);
    --padding-inset-squish: var(--space) calc(var(--space) / 2);
    --padding-inset-squish-s: var(--space-s) calc(var(--space-s) / 2);
    --padding-inset-squish-xs: var(--space-xs) calc(var(--space-xs) / 2);

    /* Minor Third Ratio (1.200) with 16px Base */
    --font-size-5xl: 2.986rem; /* 47.78px */
    --font-size-4xl: 2.488rem; /* 39.81px */
    --font-size-3xl: 2.074rem; /* 33.18px */
    --font-size-2xl: 1.728rem; /* 27.65px */
    --font-size-xl: 1.44rem; /* 23.04px */
    --font-size-l: 1.2rem; /* 19.2px */
    --font-size: 1rem; /* 16px */
    --font-size-s: 0.833rem; /* 13.33px */

    --font-weight-light: 300;
    --font-weight: 400;
    --font-weight-bold: 600;

    --font-letter-spacing-xl: 0.2rem;
    --font-letter-spacing-l: 0.1rem;
    --font-letter-spacing: 0.04rem;

    --font-family-primary: 'Oswald', sans-serif;
    --font-family-secondary: 'Arial', sans-serif;

    --content-max-width: 1200px;
    --content-max-width-half: calc(var(--content-max-width) * 0.75);

    --border-height-s: 5px;
    --border-height: 8px;
    --border-height-extreme: 80px;

    --primary-color-light: var(--color-red-40);
    --primary-color: var(--color-red-50);
    --primary-color-dark: var(--color-red-60);
}