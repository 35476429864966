@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,600&display=swap');

html {
    box-sizing: border-box;
    height: 100%;
    min-height: 100%;
}
  
*,
::before,
::after {
    box-sizing: inherit;
}

body {
    background-color: var(--color-cream-40);
    color: var(--color-brown-60);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--font-family-secondary);
    font-size: var(--font-size);
    height: 100%;
    line-height: 1.25;
    margin: 0;
    min-height: 100%;
    padding: 0;
}

body * {
    margin-bottom: 0;
}

.app-root {
    height: 100%;
    min-height: 100%;
}

.ccbw-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
}

.ccbw-page__content {
    flex-grow: 1;
}

.ccbw-404 {
    background-color: var(--color-white);
    margin: -200px var(--space) 0 var(--space);
    padding: var(--padding-inset-2xl);
    padding-top: var(--space-l);
    text-align: center;
}

.ccbw-404 h2,
.ccbw-404__link {
    display: block;
    margin: var(--margin-stack-s);
}