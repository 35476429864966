@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ccbw-logo-link {
    display: inline-block;
}

.ccbw-header .ccbw-logo-link__image {
    display: block;
    height: 150px;
    width: auto;
}

.ccbw-nav {
    --nav-link-color: var(--color-white);
    --nav-link-color-interaction: var(--color-white);
    --nav-link-color-active: var(--color-white);
    --nav-link-color-visited: var(--color-white);
    --nav-link-background-color: var(--color-white);
    --nav-link-background-color-interaction: var(--color-red-40);
    --nav-link-background-color-active: var(--color-red-60);

    flex-basis: 100%;
    padding: 0 var(--space-2xs);
    width: 100%;
}

.ccbw-nav__list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
}

.ccbw-nav__item {
    flex-shrink: 0;
    height: 42px;
    width: 42px;
}

.ccbw-nav__link {
    border-radius: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--nav-link-color);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
}

.ccbw-nav__link:visited {
    color: var(--nav-link-color-visited);
}

.ccbw-nav__link:active {
    color: var(--nav-link-color-active);
}

.ccbw-nav__link:hover,
.ccbw-nav__link:focus {
    background-color: var(--nav-link-background-color-interaction);
    color: var(--nav-link-color-interaction);
}

.ccbw-nav__link--active,
.ccbw-nav__link:hover.ccbw-nav__link--active, 
.ccbw-nav__link:focus.ccbw-nav__link--active {
    background-color: var(--nav-link-background-color-active);
    border: 1px solid var(--nav-link-background-color-active);
}

.ccbw-nav__date {
    display: block;
}

.ccbw-nav__day {
    margin: 0 auto;
    display: none;
}

.ccbw-nav__day:first-letter {
    visibility: visible;
}

.ccbw-nav__date {
    font-size: var(--font-size);
}

@media screen and (min-width: 576px) {
    .ccbw-nav__item {
        height: 65px;
        width: 65px;
    }

    .ccbw-nav__day {
        font-size: var(--font-size);
        display: block;
    }
}
 
@media screen and (min-width: 768px) {
    .ccbw-nav {
        padding: 0;
    }

    .ccbw-nav__item {
        height: 80px;
        width: 80px;
    }

    .ccbw-nav__day {
        font-size: var(--font-size-s);
    }

    .ccbw-nav__date {
        font-size: var(--font-size-xl);
    }
}
.ccbw-header__logo-container {
    background-color: var(--primary-color-dark);
    padding: var(--space-xs) 0;
}

.ccbw-header__nav-container {
    background-color: var(--primary-color);
    padding: var(--space) 0;
}

.ccbw-header__container {
    display: flex;
    justify-content: center;
}


.ccbw-footer {
    background-color: var(--color-brown-60);
    border-top: var(--border-height) solid var(--color-brown-50);
    color: var(--color-cream-50);
    flex-shrink: 0;
    margin: var(--margin-stack-xl);
    padding: var(--padding-inset-xl);
}

.ccbw-footer a,
.ccbw-footer a:visited,
.ccbw-footer a:hover,
.ccbw-footer a:focus,
.ccbw-footer a:active {
    color: var(--color-cream-50);
}

.ccbw-footer__info {
    display: flex;
    justify-content: space-between;
}

.ccbw-footer__left,
.ccbw-footer__right {
    flex-basis: 50%;
}

.ccbw-footer__left *,
.ccbw-footer__right * {
    margin: 0;
}

.ccbw-footer__right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.ccbw-footer__address,
.ccbw-footer__phone {
    font-size: var(--font-size-s);
}

.ccbw-footer__address {
    font-style: normal;
}

/* @media screen and (min-width: 768px) {
    .ccbw-footer {
        padding: var(--space-2xl) var(--space-l);
    }

    .ccbw-footer__right {
        text-align: right;
    }
} */

.ccbw-event {
    display: flex;
    flex-direction: column;
}

.ccbw-event__image-container {
    align-items: center;
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: var(--margin-stack);
    order: 1;
    width: 100%;
}

.ccbw-event__image {
    flex-basis: 45%;
    margin: var(--margin-inline);
    margin-top: var(--space);
    width: 45%;
}

.ccbw-event__container {
    flex-basis: 100%;
    width: 100%;
}

.ccbw-event__header {
    display: flex;
}

.ccbw-event__time,
.ccbw-event__location {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-bold);
}

.ccbw-event__time {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    padding-right: var(--space-l);
    text-transform: uppercase;
}

.ccbw-event__time:before,
.ccbw-event__location:before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.ccbw-event__time:before {
    background-image: url(/static/media/clock.c748caa2.svg);
}

.ccbw-event__location:before {
    background-image: url(/static/media/map-pin.aefcc14d.svg);
}

.ccbw-event__end-time,
.ccbw-event__address {
    color: var(--primary-color);
}

.ccbw-event__location {
    flex-grow: 1;
    margin-left: var(--space-l);
}

.ccbw-event__time {
    margin: 0;
}

.ccbw-event__start-time,
.ccbw-event__host {
    margin: var(--margin-stack-s);
}

.ccbw-event__address {
    font-size: var(--font-size);
    font-style: normal;
    font-weight: var(--font-weight);
}

.ccbw-event__title {
    font-weight: var(--font-weight-light);
    margin: var(--margin-stack);
}

.ccbw-event__details {
    margin: var(--margin-stack-s);
}

.ccbw-event__description {
    margin: var(--margin-stack-s);
}

.ccbw-event__link {
    display: inline-block;
    margin: var(--margin-stack);
}

.ccbw-event__breweries {
    font-size: var(--font-size-l);
    font-style: italic;
}


@media screen and (min-width: 768px) {
    .ccbw-event {
        flex-direction: row;
    }

    .ccbw-event__image-container {
        display: block;
        flex-basis: 20%;
        margin: var(--margin-inline-l);
        order: 0;
        width: 20%;
    }

    .ccbw-event__image {
        flex-basis: 100%;
        margin: var(--margin-stack);
        width: 100%;
    }

    .ccbw-event__image:nth-of-type(1) {
        margin: 0;
    }

    .ccbw-event__container {
        flex-basis: 80%;
        width: 80%;
    }
}

/* styling for the special festival event card*/
.ccbw-festival .ccbw-event__container {
    flex-basis: 100%;
    width: 100%;
}

.ccbw-festival .ccbw-event__header {
    margin: var(--margin-stack);
    flex-wrap: wrap;
}

.ccbw-event__times {
    display: flex;
    flex-basis: 100%;
    width: 100%;
}

.ccbw-festival .ccbw-event__time {
    text-transform: none;
}

.ccbw-event__time:nth-of-type(2) {
    border: 0;
    margin-left: var(--space-l)
}

.ccbw-festival .ccbw-event__end-time {
    margin: 0;
    text-transform: uppercase;
}

.ccbw-festival .ccbw-event__location {
    margin: var(--margin-stack);
    flex-basis: 100%;
    width: 100%;
}

.ccbw-event__button {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 0;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    color: var(--color-white);
    font-size: var(--font-size-l);
    background-color: var(--color-green-40);
    padding: var(--padding-inset);
    width: 100%;
    margin: var(--margin-stack);
    text-decoration: none;
    display: block;
    text-align: center;
}

.ccbw-event__button:visited {
    background-color: var(--color-green-40);
    color: var(--color-white);
}

.ccbw-event__button:hover {
    cursor: pointer;
    background-color: var(--color-green-50);
    color: var(--color-white);
    text-decoration: none;
}

.ccbw-event__button:focus {
    background-color: var(--color-green-50);
    color: var(--color-white);
    text-decoration: none;
}

.ccbw-event__button:active {
    background-color: var(--color-green-60);
    color: var(--color-white);
}

.ccbw-event-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.ccbw-home-page .ccbw-event-list {
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    margin: var(--margin-stack);
    padding-left: var(--space-s);
}

.ccbw-home-page .ccbw-event-list__item {
    font-size: var(--font-size);
    margin: var(--margin-stack-s);
}

.ccbw-event-page .ccbw-event-list {
    background-color: var(--color-white);
    margin: -116px var(--space) 0 var(--space);
    padding: var(--padding-inset);
}

.ccbw-event-page .ccbw-event-list__item {
    border-bottom: var(--border-height-s) dotted var(--primary-color-dark);
    margin: var(--margin-stack-l);
    padding-bottom: var(--space-l);
}

.ccbw-event-page .ccbw-event-list__item:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
}

.ccbw-event-page .ccbw-event-list__item:first-of-type {
    margin: 0;
}

@media screen and (min-width: 768px) {
    .ccbw-home-page .ccbw-event-list {
        margin: var(--margin-stack);
    }

    .ccbw-event-page .ccbw-event-list {
        margin: -200px 0 0 0;
    }
    
    .ccbw-home-page .ccbw-event-list__item {
        font-size: var(--font-size-l);
        margin: var(--margin-stack-s);
    }
}

.ccbw-home-page {
    display: flex;
    flex-wrap: wrap;
}

.ccbw-home-page__banner {
    align-items: center;
    /* background-image: url('../../assets/images/video-still-min.png'); */
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    border-bottom: var(--border-height-extreme) solid var(--primary-color-dark);
    display: flex;
    flex-basis: 100%;
    height: 300px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: -1;
}

.ccbw-home-page__video {
    display: block;
    width: 100%;
    z-index: -100;
}


.ccbw-home-page__image {
    height: 200px;
    position: absolute;
    top: -8px;
}

.ccbw-home-page__events {
    background-color: var(--color-white);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: -116px var(--space) 0 var(--space);
    padding: var(--space-l) var(--space);
}

.ccbw-home-page__item {
    flex-basis: 50%;
    margin-top: var(--space-xl);
    width: 100%;
}

.ccbw-home-page__item {
    flex-basis: calc((100% / 2) - var(--space-xs) * 2);
    width: calc((100% / 2) - var(--space-xs) * 2);
}

.ccbw-home-page__item:nth-of-type(odd) {
    margin-right: var(--space-xs);
}

.ccbw-home-page__item:nth-of-type(1),
.ccbw-home-page__item:nth-of-type(2) {
    margin-top: 0;
}

.ccbw-home-page__day,
.ccbw-home-page__date {
    display: block;
    text-transform: uppercase;
}

.ccbw-home-page__day {
    font-size: var(--font-size-l);
}

.ccbw-home-page__date {
    font-size: var(--font-size-2xl);
    color: var(--primary-color-dark);
}

.ccbw-home-page__divider {
    background-color: var(--primary-color);
    display: block;
    height: 3px;
    margin: var(--margin-stack-xs);
    width: 80px;
}


@media screen and (min-width: 768px) {
    .ccbw-home-page__banner {
        height: 450px;
    }

    .ccbw-home-page__image {
        height: 400px;
        top: -40px;
    }
    
    .ccbw-home-page__events {
        padding: var(--space-xl) var(--space-xl) var(--space-2xl);
    }

    .ccbw-home-page__item {
        flex-basis: calc((100% / 3) - var(--space-xs) * 2);
        margin-top: var(--space-xl);
        width: calc((100% / 3) - var(--space-xs) * 2);
    }

    .ccbw-home-page__item {
        margin-right: var(--space-xs);
    }

    .ccbw-home-page__item:nth-of-type(3) {
        margin-top: 0;
    }

    .ccbw-home-page__item:last-of-type {
        margin-top: var(--space-xl);
    }
}

.ccbw-event-page__header {
    border-bottom: var(--border-height-s) solid var(--primary-color);
    padding: var(--space);
}

.ccbw-event-page__day,
.ccbw-event-page__date {
    display: block;
    text-transform: uppercase;
}

.ccbw-event-page__day {
    font-size: var(--font-size-l);
}

.ccbw-event-page__date {
    color: var(--primary-color-dark);
}

.ccbw-event-page__divider {
    background-color: var(--primary-color);
    display: block;
    height: 5px;
    margin: var(--margin-stack-s);
    width: 80px;
}

.ccbw-event-page__banner {
    background-image: url(/static/media/kegs-min.828901df.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: var(--border-height-extreme) solid var(--primary-color-dark);
    height: 320px;
}

@media screen and (min-width: 1064px) {
    .ccbw-event-page__banner {
        height: 420px;
    }
}

/* @media screen and (min-width: 1200px) {
    .ccbw-event-page__banner {
        height: 520px;
    }
} */

:root {
    --color-black: #000000;
    --color-white: #ffffff;

    --color-red-40: #954341;
    --color-red-50: #832E2D;
    --color-red-60: #631616;
    --color-gold-40: #A27D3A;
    --color-gold-50: #9B742C;
    --color-gold-60: #745721;
    --color-cream-40: #F5F5F2;
    --color-cream-50: #D7D2C8;
    --color-cream-60: #C2BEB5;
    --color-brown-40: #695543;
    --color-brown-50: #533F2F;
    --color-brown-60: #312117;
    --color-blue-40: #383D86;
    --color-blue-50: #292F7D;
    --color-blue-60: #1E235E;
    --color-green-40: #16756F;
    --color-green-50: #056B65;
    --color-green-60: #03504C;

    /* Golden Ratio (1.618) with 16px Base */
    --space-2xl: 4.236rem; /* 67.77px */
    --space-xl: 2.618rem; /* 41.89px */
    --space-l: 1.618rem; /* 25.89px */
    --space: 1rem; /* 16px */
    --space-s: 0.618rem; /* 9.89px */
    --space-xs: 0.382rem; /* 6.11px */
    --space-2xs: 0.236rem; /* 3.78px */

    --margin-stack-2xl: var(--space-2xl) 0 0 0;
    --margin-stack-xl: var(--space-xl) 0 0 0;
    --margin-stack-l: var(--space-l) 0 0 0;
    --margin-stack: var(--space) 0 0 0;
    --margin-stack-s: var(--space-s) 0 0 0;
    --margin-stack-xs: var(--space-xs) 0 0 0;
    
    --margin-inline-2xl: 0 var(--space-2xl) 0 0;
    --margin-inline-xl: 0 var(--space-xl) 0 0;
    --margin-inline-l: 0 var(--space-l) 0 0;
    --margin-inline: 0 var(--space) 0 0;
    --margin-inline-s: 0 var(--space-s) 0 0;
    --margin-inline-xs: 0 var(--space-xs) 0 0;

    --padding-inset-2xl: var(--space-2xl);
    --padding-inset-xl: var(--space-xl);
    --padding-inset-l: var(--space-l);
    --padding-inset: var(--space);
    --padding-inset-s: var(--space-s);
    --padding-inset-xs: var(--space-xs);

    --padding-inset-squish-2xl: var(--space-2xl) calc(var(--space-2xl) / 2);
    --padding-inset-squish-xl: var(--space-xl) calc(var(--space-xl) / 2);
    --padding-inset-squish-l: var(--space-l) calc(var(--space-l) / 2);
    --padding-inset-squish: var(--space) calc(var(--space) / 2);
    --padding-inset-squish-s: var(--space-s) calc(var(--space-s) / 2);
    --padding-inset-squish-xs: var(--space-xs) calc(var(--space-xs) / 2);

    /* Minor Third Ratio (1.200) with 16px Base */
    --font-size-5xl: 2.986rem; /* 47.78px */
    --font-size-4xl: 2.488rem; /* 39.81px */
    --font-size-3xl: 2.074rem; /* 33.18px */
    --font-size-2xl: 1.728rem; /* 27.65px */
    --font-size-xl: 1.44rem; /* 23.04px */
    --font-size-l: 1.2rem; /* 19.2px */
    --font-size: 1rem; /* 16px */
    --font-size-s: 0.833rem; /* 13.33px */

    --font-weight-light: 300;
    --font-weight: 400;
    --font-weight-bold: 600;

    --font-letter-spacing-xl: 0.2rem;
    --font-letter-spacing-l: 0.1rem;
    --font-letter-spacing: 0.04rem;

    --font-family-primary: 'Oswald', sans-serif;
    --font-family-secondary: 'Arial', sans-serif;

    --content-max-width: 1200px;
    --content-max-width-half: calc(var(--content-max-width) * 0.75);

    --border-height-s: 5px;
    --border-height: 8px;
    --border-height-extreme: 80px;

    --primary-color-light: var(--color-red-40);
    --primary-color: var(--color-red-50);
    --primary-color-dark: var(--color-red-60);
}

html {
    box-sizing: border-box;
    height: 100%;
    min-height: 100%;
}
  
*,
::before,
::after {
    box-sizing: inherit;
}

body {
    background-color: var(--color-cream-40);
    color: var(--color-brown-60);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--font-family-secondary);
    font-size: var(--font-size);
    height: 100%;
    line-height: 1.25;
    margin: 0;
    min-height: 100%;
    padding: 0;
}

body * {
    margin-bottom: 0;
}

.app-root {
    height: 100%;
    min-height: 100%;
}

.ccbw-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
}

.ccbw-page__content {
    flex-grow: 1;
}

.ccbw-404 {
    background-color: var(--color-white);
    margin: -200px var(--space) 0 var(--space);
    padding: var(--padding-inset-2xl);
    padding-top: var(--space-l);
    text-align: center;
}

.ccbw-404 h2,
.ccbw-404__link {
    display: block;
    margin: var(--margin-stack-s);
}
h1,
h2,
h3,
h4, 
h5, 
h6 {
    font-family: var(--font-family-primary);
    font-weight: var(--font-weight);
    letter-spacing: var(--font-letter-spacing);
}

h1 {
    font-size: var(--font-size-5xl);
    margin: var(--margin-stack-xl);
}

h2 {
    font-size: var(--font-size-4xl);
    margin: var(--margin-stack-l);
}

h3 {
    font-size: var(--font-size-3xl);
    margin: var(--margin-stack);
}

h4 {
    font-size: var(--font-size-2xl);
    margin: var(--margin-stack-xl);
}

h5 {
    font-size: var(--font-size-xl);
    margin: var(--margin-stack-l);
}

h6 {
    font-size: var(--font-size-l);
    margin: var(--margin-stack);
}

p {
    line-height: 1.5;
    margin: var(--margin-stack-s);
}


h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: var(--margin-stack-xl);
}

h1 + p, 
h2 + p, 
h3 + p, 
h4 + p, 
h5 + p, 
h6 + p {
    margin: var(--margin-stack-s);
}

h1 + ol, 
h2 + ol, 
h3 + ol, 
h4 + ol, 
h5 + ol, 
h6 + ol {
    margin: var(--margin-stack-xs);
}

h1 + ul, 
h2 + ul, 
h3 + ul, 
h4 + ul, 
h5 + ul, 
h6 + ul {
    margin: var(--margin-stack-xs);
}

h1 + dl,
h2 + dl,
h3 + dl,
h4 + dl,
h5 + dl,
h6 + dl {
    margin: var(--margin-stack-xs);
}

h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6 {
    margin-top: 0;
}

a,
a:visited {
    --link-color: var(--color-blue-60);
    --link-color-interactive: var(--color-blue-40);
    --link-color: var(--color-green-60);
    --link-color-interactive: var(--color-green-40);
    
    color: var(--link-color);
}

a:hover,
a:focus,
a:active {
    color: var(--link-color-interactive);
}

ol,
ul {
    margin-top: var(--space);

}

ol ol,
ol ul,
ul ol,
ul ul  {
    margin-top: 0;
}
.contain {
    margin: 0 auto;
    max-width: var(--content-max-width);
}

.contain-extreme {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--content-max-width-half);
}

.sr-only {
    border: 0; 
    clip: rect(0,0,0,0);
    color: #000000;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    visibility: hidden;
}



