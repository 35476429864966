.ccbw-event-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.ccbw-home-page .ccbw-event-list {
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    margin: var(--margin-stack);
    padding-left: var(--space-s);
}

.ccbw-home-page .ccbw-event-list__item {
    font-size: var(--font-size);
    margin: var(--margin-stack-s);
}

.ccbw-event-page .ccbw-event-list {
    background-color: var(--color-white);
    margin: -116px var(--space) 0 var(--space);
    padding: var(--padding-inset);
}

.ccbw-event-page .ccbw-event-list__item {
    border-bottom: var(--border-height-s) dotted var(--primary-color-dark);
    margin: var(--margin-stack-l);
    padding-bottom: var(--space-l);
}

.ccbw-event-page .ccbw-event-list__item:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
}

.ccbw-event-page .ccbw-event-list__item:first-of-type {
    margin: 0;
}

@media screen and (min-width: 768px) {
    .ccbw-home-page .ccbw-event-list {
        margin: var(--margin-stack);
    }

    .ccbw-event-page .ccbw-event-list {
        margin: -200px 0 0 0;
    }
    
    .ccbw-home-page .ccbw-event-list__item {
        font-size: var(--font-size-l);
        margin: var(--margin-stack-s);
    }
}
